import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const url = 'https://sunnyronaldocompany.com/Backend/develop/request.php';

const options = {
  headers: new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded'
  ),
};
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  UploadImage(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('upload_file', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetAllUser(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_user_filter', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetAllPartnerByFilter(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_partner_by_filter', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }
  GetAllStudentByFilter(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_student_by_filter', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }
  GetAllFormByFilter(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_form_filter', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }
  GetJobApplyOfPostId(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_job_apply_by_post_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetStaffByCompanyId(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_staff_info_by_company_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetStaffOfStudent(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_staff_by_student_id_list', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetInterviewDataByStudentId(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_upcoming_interview_by_student_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetUserById(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetUserByIdList(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_by_id_list', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetMessageByJobApplyId(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_message_by_job_apply_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  UpdateUser(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_user', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  UpdatePost(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('edit_job', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  CreateJobApplyData(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_create_job_apply', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetFormData(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_form', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  UpdateForm(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('edit_form', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetFormDataByStudentId(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_form_by_student_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetFormDataById(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_form_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetPostByFilter(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_post_by_filter', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetPostByIdList(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_job_by_id_list', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetExcelAppointmentReport(filter = {}): Promise<any> {
    let payload = { verify_token: '' };

    let userData = localStorage.getItem('sunny_ronaldo_cms_user');

    if (userData !== undefined && userData !== null && userData !== '') {
      userData = JSON.parse(userData);
      payload.verify_token = userData['verify_token'];
    }

    if (Object.keys(filter).length > 0) {
      payload = Object.assign(payload, filter);
    }

    let body = new URLSearchParams();
    body.set('get_excel_appointment_report', JSON.stringify(payload));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetJobPostById(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_job_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetJobApplyByStudentId(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_job_apply_by_student_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetJobApplyByFormId(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_job_apply_by_form_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetJobApplyByPostId(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_job_apply_by_post_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  RemoveUser(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_user_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  RemoveForm(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_form_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  RemovePost(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_post_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  RemoveJobApply(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_job_apply_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetAllTeam(): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_get_all_team', '');

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetTeamDataById(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_team_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  AddTeam(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('create_team', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  UpdateTeam(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('edit_team', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetAllCareer(): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_get_all_career', '');

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetCareerDataById(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_career_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  AddCareer(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('create_career', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  UpdateCareer(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('edit_career', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  RemoveCareer(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_career', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  RemoveTeam(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_team', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetAllContactUs(): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_contact_us', '');

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetContactUsDataById(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_contact_us_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetAllCoupon(): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_coupon', '');

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetCouponDataById(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_coupon_by_id', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  AddCoupon(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('create_coupon', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  UpdateCoupon(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('edit_coupon', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  SendCert(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('send_cert_to_student', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  Login(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_login', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  Logout(id: number): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_logout', JSON.stringify({ id }));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  VerifyAdminToken(verifyToken: string, admin_id: number = -1) {
    const payload: any = { verify_token: verifyToken };

    if (admin_id !== -1) {
      payload.admin_id = admin_id;
    }

    let body = new URLSearchParams();
    body.set('cms_get_admin_by_token', JSON.stringify(payload));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  GetPrice(): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_get_price', '');

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }

  UpdatePrice(data): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_update_price', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async (data) => {
        let temp = data;
        if (temp['result'] == 'success') {
          resolve(temp);
        } else if (temp['result'] == 'fail') {
          resolve(temp);
        } else if (temp['result'] == 'error') {
          reject(temp);
        }
      }),
        async (error) => {
          return error;
        };
    });
  }
}
