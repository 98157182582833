import { Component } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from './services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Partner', url: '/partner', icon: 'briefcase' },
    { title: 'Helper', url: '/helper', icon: 'person' },
    { title: 'Order Record', url: '/order-record', icon: 'albums' },
    { title: 'Job Post', url: '/job-post', icon: 'document' },
    /* { title: 'Coupon', url: '/coupon', icon: 'cash' }, */
    //{ title: 'Our Team', url: '/team', icon: 'people' },
    //{ title: 'Career Insighter', url: '/career-insighter', icon: 'images' },
    { title: 'Contact Us', url: '/contact-us', icon: 'mail' },
    //{ title: 'Setting', url: '/setting', icon: 'cog' },
    
  ];
  current_url = "";
  constructor(
    private platform: Platform,
    private router: Router,
    private location: Location,
    public menuCtrl: MenuController,
    public commonService: CommonService
  ) {
    this.router.events.subscribe(val => {
      if (location.path() != "") {
        this.current_url = this.router.url;
      }
    })
  }
}
