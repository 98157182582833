import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LogoutOnlyGuard } from './guards/logout-only.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [LogoutOnlyGuard],
  },
  {
    path: 'partner',
    loadChildren: () =>
      import('./pages/partner/partner.module').then((m) => m.PartnerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'partner-detail/:id',
    loadChildren: () =>
      import('./pages/partner-detail/partner-detail.module').then(
        (m) => m.PartnerDetailPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'helper',
    loadChildren: () =>
      import('./pages/student/student.module').then((m) => m.StudentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'helper-detail/:id',
    loadChildren: () =>
      import('./pages/student-detail/student-detail.module').then(
        (m) => m.StudentDetailPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'order-record',
    loadChildren: () =>
      import('./pages/order-record/order-record.module').then(
        (m) => m.OrderRecordPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'order-record-detail/:id',
    loadChildren: () =>
      import('./pages/order-record-detail/order-record-detail.module').then(
        (m) => m.OrderRecordDetailPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'job-post',
    children: [
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/job-post/job-post.module').then(
            (m) => m.JobPostPageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/job-post/job-post.module').then(
            (m) => m.JobPostPageModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'job-post-detail/:id',
    loadChildren: () =>
      import('./pages/job-post-detail/job-post-detail.module').then(
        (m) => m.JobPostDetailPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'job-interview-popup',
    loadChildren: () =>
      import('./pages/job-interview-popup/job-interview-popup.module').then(
        (m) => m.JobInterviewPopupPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'assign-job-popup',
    loadChildren: () =>
      import('./pages/assign-job-popup/assign-job-popup.module').then(
        (m) => m.AssignJobPopupPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-pop',
    loadChildren: () =>
      import('./pages/message-pop/message-pop.module').then(
        (m) => m.MessagePopPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'timesheet-pop',
    loadChildren: () =>
      import('./pages/timesheet-pop/timesheet-pop.module').then(
        (m) => m.TimesheetPopPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'aggreement-pop',
    loadChildren: () =>
      import('./pages/aggreement-pop/aggreement-pop.module').then(
        (m) => m.AggreementPopPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'evalution-form-popup',
    loadChildren: () =>
      import('./pages/evalution-form-popup/evalution-form-popup.module').then(
        (m) => m.EvalutionFormPopupPageModule
      ),
    canActivate: [AuthGuard],
  },
/*   {
    path: 'career-insighter',
    loadChildren: () =>
      import('./pages/career-insighter/career-insighter.module').then(
        (m) => m.CareerInsighterPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'career-insighter-detail/:id',
    loadChildren: () =>
      import(
        './pages/career-insighter-detail/career-insighter-detail.module'
      ).then((m) => m.CareerInsighterDetailPageModule),
    canActivate: [AuthGuard],
  }, */
/*   {
    path: 'team',
    loadChildren: () =>
      import('./pages/team/team.module').then((m) => m.TeamPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'team-detail/:id',
    loadChildren: () =>
      import('./pages/team-detail/team-detail.module').then(
        (m) => m.TeamDetailPageModule
      ),
    canActivate: [AuthGuard],
  }, */
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'contact-us-detail/:id',
    loadChildren: () =>
      import('./pages/contact-us-detail/contact-us-detail.module').then(
        (m) => m.ContactUsDetailPageModule
      ),
    canActivate: [AuthGuard],
  },
/*   {
    path: 'coupon',
    loadChildren: () =>
      import('./pages/coupon/coupon.module').then((m) => m.CouponPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'coupon-detail/:id',
    loadChildren: () =>
      import('./pages/coupon-detail/coupon-detail.module').then(
        (m) => m.CouponDetailPageModule
      ),
    canActivate: [AuthGuard],
  }, */
/*   {
    path: 'setting',
    loadChildren: () =>
      import('./pages/setting/setting.module').then((m) => m.SettingPageModule),
  }, */
  {
    path: '',
    redirectTo: '/partner',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
