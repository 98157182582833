import { Injectable, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public show_sidemenu = true;
  public isLoading = false;
  public img_head = 'https://sunnyronaldocompany.com/Media/';

  public defaultTimeOffset = 480;
  constructor(
    private _snackBar: MatSnackBar,
    public httpService: HttpService,
    private router: Router,
    private nav: NavController,
    public dialog: MatDialog
  ) {
    if (window.innerWidth <= 992) {
      this.show_sidemenu = false;
    }
  }

  isValid(data) {
    if (data !== undefined && data !== null && data !== '') {
      if (typeof data === 'object') {
        return Object.keys(data).length > 0;
      } else if (Array.isArray(data)) {
        return data.length > 0;
      }

      if (data.length > 0) {
        return data.trim().length > 0;
      }
      return true;
    } else {
      return false;
    }
  }

  async Logout() {
    let userData = localStorage.getItem('sunny_ronaldo_cms_user');
    if (this.isValid(userData)) {
      userData = JSON.parse(userData);
      let result = await this.httpService.Logout(userData['id']);
      console.log(result);
    }

    window.localStorage.removeItem('sunny_ronaldo_cms_user');
    this.NavigateRoot('login');
  }

  toggleMenu() {
    if (this.show_sidemenu) {
      this.show_sidemenu = false;
    } else {
      this.show_sidemenu = true;
    }
  }

  openSnackBar(
    message: string,
    action?: string,
    durantion?: number,
    horizontalPosition?,
    verticalPosition?
  ) {
    return this._snackBar.open(message, action != undefined ? action : 'OK', {
      duration: durantion != undefined ? durantion : 2500,
      horizontalPosition:
        horizontalPosition != undefined ? horizontalPosition : 'end',
      verticalPosition:
        verticalPosition != undefined ? verticalPosition : 'top',
    });
  }
  ToPage(page) {
    this.nav.navigateForward(page);
  }
  BackPage() {
    this.nav.back();
  }
  BackUntilPage(page) {
    // all the pages in the stack until the navigated page is found will be popped
    this.nav.navigateBack(page);
  }
  NavigateRoot(page = '') {
    // all existing pages in the stack will be removed
    // the navigated page will become the single page in the stack
    this.nav.navigateRoot(page);
  }
  OpenNewTab(url) {
    console.log(url);
    window.open(url, '_blank');
  }

  Reload() {}

  firstFileToBase64(fileImage: File): Promise<{}> {
    return new Promise((resolve, reject) => {
      let fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  async deleteDataAlert(data_type, id) {
    if (this.isLoading) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined && result) {
        console.log(result);
        if (result == true) {
          this.deleteData(data_type, id);
        }
      }
    });
  }

  deleteData(data_type, id) {
    console.log(data_type);
    console.log(id);
    switch (data_type) {
      case 'team':
        this.httpService.RemoveTeam({ id: id }).then((res) => {
          if (res.result == 'success' && res.data != null) {
            let ref = this.openSnackBar('Team Removed Successful');

            ref.afterDismissed().subscribe((action) => {
              this.isLoading = false;
              this.NavigateRoot('team');
            });
          } else {
            this.isLoading = false;
            this.openSnackBar('Remove team Fail');
          }
        });
        break;
      case 'user':
        this.httpService.RemoveUser({ id: id }).then((res) => {
          if (res.result == 'success' && res.data != null) {
            let ref = this.openSnackBar('User Removed Successful');

            ref.afterDismissed().subscribe((action) => {
              this.isLoading = false;
              this.NavigateRoot('partner');
            });
          } else {
            this.isLoading = false;
            this.openSnackBar('Remove user Fail');
          }
        });
        break;
      case 'form':
        this.httpService.RemoveForm({ id: id }).then((res) => {
          if (res.result == 'success' && res.data != null) {
            let ref = this.openSnackBar('Form Removed Successful');

            ref.afterDismissed().subscribe((action) => {
              this.isLoading = false;
              this.BackPage();
            });
          } else {
            this.isLoading = false;
            this.openSnackBar('Remove form Fail');
          }
        });
        break;
      case 'post':
        this.httpService.RemovePost({ id: id }).then((res) => {
          if (res.result == 'success' && res.data != null) {
            let ref = this.openSnackBar('Post Removed Successful');

            ref.afterDismissed().subscribe((action) => {
              this.isLoading = false;
              this.NavigateRoot('job-post');
            });
          } else {
            this.isLoading = false;
            this.openSnackBar('Remove Post Fail');
          }
        });
        break;
      case 'job_apply':
        this.httpService.RemoveJobApply({ id: id }).then((res) => {
          if (res.result == 'success' && res.data != null) {
            let ref = this.openSnackBar('Applied Job Removed Successful');

            ref.afterDismissed().subscribe((action) => {
              this.isLoading = false;
              this.BackPage();
            });
          } else {
            this.isLoading = false;
            this.openSnackBar('Remove Applied Job Fail');
          }
        });
        break;
      default:
        this.openSnackBar('Remove Data Fail');
        this.isLoading = false;
        break;
    }
  }

  currentTimeString(date) {
    var now = date;
    if (now == '') {
      now = new Date();
    }
    return (
      now.getUTCFullYear() +
      '-' +
      this.NumberCovert(now.getMonth() + 1) +
      '-' +
      this.NumberCovert(now.getDate()) +
      'T' +
      this.NumberCovert(now.getHours()) +
      ':' +
      this.NumberCovert(now.getMinutes()) +
      ':' +
      this.NumberCovert(now.getSeconds()) +
      'Z'
    );
  }

  getUTCoffset() {
    var str = '';
    const offsetMin = -new Date().getTimezoneOffset();
    const value = Math.abs(offsetMin);
    if (offsetMin > 0) {
      str =
        '+' +
        this.NumberCovert(Math.floor(value / 60)) +
        ':' +
        this.NumberCovert(Math.floor(value % 60));
    } else {
      str =
        '-' +
        this.NumberCovert(Math.floor(value / 60)) +
        ':' +
        this.NumberCovert(Math.floor(value % 60));
    }
    return {
      iso: str,
      offset: offsetMin,
    };
  }

  NumberCovert(num) {
    if (parseInt(num) < 10) {
      return '0' + num;
    } else {
      return num;
    }
  }

  convertLocalToServerTime(str) {
    var timezone = this.getUTCoffset();

    if (str[str.length - 1] == 'Z') {
      if (timezone.offset != this.defaultTimeOffset) {
        str = str.slice(0, -1);
        str += timezone.iso;
        var localDateTime =
          new Date(str).getTime() -
          (timezone.offset - this.defaultTimeOffset) * 60 * 1000;
        var converted = this.convertTimestamp(localDateTime);
        return (
          converted.year +
          '-' +
          converted.month +
          '-' +
          converted.day +
          'T' +
          converted.hr +
          ':' +
          converted.min +
          ':' +
          converted.sec +
          'Z'
        );
      } else {
        return str;
      }
    } else {
      console.log('Invalid JSON Time Format: ' + str);
      return str;
    }
  }

  convertTimestamp(t) {
    var a = new Date(t);
    return {
      year: a.getFullYear().toString(),
      month: this.NumberCovert(a.getMonth() + 1).toString(),
      day: this.NumberCovert(a.getDate()).toString(),
      hr: this.NumberCovert(a.getHours()).toString(),
      min: this.NumberCovert(a.getMinutes()).toString(),
      sec: this.NumberCovert(a.getSeconds()).toString(),
      weekday: a.getDay(),
    };
  }
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
})
export class ConfirmDialog {
  constructor(public dialogRef: MatDialogRef<ConfirmDialog>) {}

  close() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
