import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private commonService: CommonService,
    private httpService: HttpService
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let approve = false;
    let userData = localStorage.getItem('sunny_ronaldo_cms_user');

    if (this.commonService.isValid(userData)) {
      userData = JSON.parse(userData);
      if (this.commonService.isValid(userData['verify_token'])) {
        const verifyToken = userData['verify_token'];
        let adminID = -1;
        if (this.commonService.isValid(userData['id'])) {
          adminID = parseInt(userData['id']);
        }

        let result = await this.httpService.VerifyAdminToken(
          verifyToken,
          adminID
        );
        if (result['result'] === 'success') {
          approve = true;
        }
      }
    }

    if (!approve) {
      this.commonService.Logout();
      return false;
    }

    return approve;
  }
}
